import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      authMode: "AWS_IAM",
    };
  },
  methods: {
    async getCredentials() {
      try {
        const currentCredentials = await Auth.currentCredentials();
        // console.log("currentCredentials", currentCredentials);
        if (currentCredentials.authenticated) {
          this.authMode = "AMAZON_COGNITO_USER_POOLS";
        }

        // this.getData(targetId);
      } catch (error) {
        console.log("Error getting currentCredentials: ", error);

        this.message =
          "Error: " +
          error.errors[0].errorType +
          ". Please contact your advisor for assistance.";
      }
    },
  },
};
