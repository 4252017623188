export const customSearchAdvisorVanityName = /* GraphQL */ `
  query CustomSearchAdvisorVanityName(
    $filter: SearchableAdvisorVanityNameFilterInput
    $sort: [SearchableAdvisorVanityNameSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdvisorVanityNameAggregationInput]
  ) {
    searchAdvisorVanityNames(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        advisor {
          prospects
        }
      }
      nextToken
    }
  }
`;

export const customGetProspectCtaRequests = /* GraphQL */ `
  query CustomGetProspectCtaRequests($id: ID!) {
    getProspect(id: $id) {
      ctaRequests {
        createdAt
        id
        name
        source
        updatedAt
      }
    }
  }
`;

export const customGetAdvisorSimple = /* GraphQL */ `
  query CustomGetAdvisor($id: ID!) {
    getAdvisor(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const customGetAdvisorReportTemplate = /* GraphQL */ `
  query CustomGetAdvisorReportTemplate($id: ID!) {
    getProspect(id: $id) {
      advisor {
        id
        scoreReportTemplateId
      }
    }
  }
`;
