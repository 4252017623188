<template>
  <v-container class="mt-7">
    <div
      v-if="loading"
      class="d-flex flex-column align-center justify-center mt-12"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <template v-if="!loading && message">
      <v-container class="mt-7">
        <v-row class="align-center">
          <v-col class="text-center">
            <v-card
              v-if="message"
              outlined
              max-width="600"
              elevation="12"
              class="mx-auto py-8"
            >
              <v-card-text>
                <h3 class="mb-2">
                  {{ message }}
                </h3>
                <p>
                  <router-link
                    :to="{ path: `/score-report/${this.prospectId}` }"
                    >Return to your Risk Score Report</router-link
                  >
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <v-row v-if="!loading && !isSuccess && !message" class="align-center">
      <v-col class="text-center">
        <v-card outlined max-width="600" elevation="12" class="mx-auto py-8">
          <v-card-text>
            <h3 class="mb-2">Request Advisor Contact</h3>
            <p>
              Fill out your contact info to have a professional advisor contact
              you to assist you in reducing your Social Security risk.
            </p>
            <p class="mb-0 error--text text-caption">
              All fields are required.
            </p>
          </v-card-text>
          <v-card-text>
            <v-form v-model="valid" @submit.prevent="handleUpdateProspect">
              <v-text-field
                v-model="contact.email"
                clearable
                outlined
                label="Email"
                type="email"
                required
                class="mb-4 rs-text-field--required"
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                v-model="contact.firstName"
                clearable
                outlined
                label="First Name"
                type="text"
                required
                class="mb-4 rs-text-field--required"
                :rules="firstNameRules"
              ></v-text-field>
              <v-text-field
                v-model="contact.lastName"
                clearable
                outlined
                label="Last Name"
                type="text"
                required
                class="mb-4 rs-text-field--required"
                :rules="lastNameRules"
              ></v-text-field>
              <v-text-field
                v-model="contact.phone"
                clearable
                outlined
                label="Phone Number"
                type="tel"
                required
                class="mb-4 rs-text-field--required"
                v-maska="'###-###-####'"
                :error-messages="phoneErrorMessage"
                @input="handleValidatePhone"
              ></v-text-field>

              <v-btn
                type="submit"
                :disabled="!valid && !phoneValid"
                color="primary"
                >Submit</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div
      v-if="!message && !loading && isSuccess"
      class="mt-12 d-flex flex-column align-center justify-center"
    >
      <h1 class="mb-8 success--text">Success!</h1>

      <img
        src="https://report.riskscore.us/hs-fs/hubfs/Meeting%20Request%20-%20Risk%20Score%20Blue.png?width=400&height=278&name=Meeting%20Request%20-%20Risk%20Score%20Blue.png"
        style="width: 250px"
        class="mb-8"
      />
      <p class="text-h3">
        Your advisor has been notified and will be in touch soon!
      </p>
      <p>
        <router-link :to="{ path: `/score-report/${this.prospectId}` }"
          >Return to your Risk Score Report</router-link
        >
      </p>
    </div>
  </v-container>
</template>

<script>
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { updateProspect } from "@/graphql/mutations";
import { validatePhone } from "@/services/validatePhoneNumber";
import { customGetProspectCtaRequests } from "@/customGraphQL/customQueries";
import getUserCredentialsMixin from "@/mixins/getUserCredentialsMixin";

export default {
  mixins: [getUserCredentialsMixin],
  data() {
    return {
      valid: false,
      message: "",
      loading: true,
      prospectId: null,
      queryParamSource: null,
      existingCtaRequests: null,
      isSuccess: null,
      contact: {
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        phone: "",
      },
      phoneErrorMessage: null,
      phoneValid: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      firstNameRules: [
        (v) => !!v || "First name is required",
        (v) =>
          (v && v.length >= 2) || "First name must be at least two letters",
      ],
      lastNameRules: [
        (v) => !!v || "Last name is required",
        (v) => (v && v.length >= 2) || "Last name must be at least two letters",
      ],
    };
  },
  async beforeRouteEnter(to, from, next) {
    // react to route changes...
    next((vm) => {
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      vm.prospectId = to.params.id;
      vm.queryParamSource = to.query.source ? to.query.source : "report";
    });
  },

  async mounted() {
    if (!this.prospectId) {
      this.loading = false;
      this.message = "Sorry, the requested information is invalid or missing.";
      return;
    }

    const regex = new RegExp(
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
    );

    const regexValid = regex.test(this.prospectId);

    if (!regexValid) {
      this.loading = false;
      this.message = "Sorry, the requested information is invalid or missing.";
      return;
    } else {
      this.setup();
    }
  },
  methods: {
    async setup() {
      await this.getCredentials(this.prospectId);
      this.checkCtaRequests();
    },
    async getExistingCtaRequests(prospectId) {
      try {
        const res = await API.graphql({
          query: customGetProspectCtaRequests,
          variables: { id: prospectId },
          authMode: this.authMode,
        });

        return res.data.getProspect?.ctaRequests;
      } catch (error) {
        console.log("Error getting existing cta requests", error);
        this.message =
          "Sorry, an error has occurred. Please contact your advisor for assistance.";
      }
    },
    async checkCtaRequests() {
      try {
        // Check if existing requests exists
        this.existingCtaRequests = await this.getExistingCtaRequests(
          this.prospectId
        );

        let previouslySetContactRequest;

        if (this.existingCtaRequests) {
          previouslySetContactRequest = this.existingCtaRequests.find(
            (request) => request["name"] === "reduce-risk-request"
          );
        }

        if (previouslySetContactRequest) {
          console.log(
            "previouslySetContactRequest",
            previouslySetContactRequest
          );
          this.message =
            "Your advisor has already been informed and will reach out soon to assist you further.";
        } else {
          // New entry
          this.ctaRequestObj = {
            id: uuidv4(),
            name: "reduce-risk-request",
            source: this.queryParamSource,
            createdAt: new Date().toISOString(),
            updatedAt: null,
          };
        }
      } catch (error) {
        this.message =
          "Sorry, an error has occurred. Please refresh and try again.";
      } finally {
        this.loading = false;
      }
    },
    async handleUpdateProspect() {
      this.loading = true;
      try {
        // look up prospect, they have have an updated
        this.contact.id = this.prospectId;

        let newCtaRequests;
        if (this.existingCtaRequests) {
          // add new contact request to existing array
          newCtaRequests = this.existingCtaRequests.concat(this.ctaRequestObj);
        } else {
          newCtaRequests = [this.ctaRequestObj];
        }

        const updateData = {
          ...this.contact,
          ctaRequests: newCtaRequests,
        };

        await API.graphql({
          query: updateProspect,
          variables: { input: updateData },
          authMode: this.authMode,
        });

        this.isSuccess = true;
      } catch (error) {
        console.log("Error updating prospect...", error);
        this.message =
          "Sorry, an error has occurred. Please contact your advisor for assistance.";
      } finally {
        this.loading = false;
      }
    },
    async handleValidatePhone(num) {
      if (num && num.length === 12) {
        try {
          const response = await validatePhone(num);
          console.log("response", response);
          if (response.success) {
            this.phoneErrorMessage = null;
            this.phoneValid = true;
          } else {
            this.phoneErrorMessage = "Please enter a valid phone number.";
            this.phoneValid = false;
          }
        } catch (error) {
          console.log("ERROR", error);
          this.phoneErrorMessage = "Please enter a valid phone number.";
          this.phoneValid = false;
        }
      } else {
        this.phoneErrorMessage = "Please enter a valid phone number.";
        this.phoneValid = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
